import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useQuery, useMutation } from 'react-query';
import { path } from 'ramda';
import axios from 'axios';

import Vacancy from './Vacancy';
import { COMPANY, DOMAIN } from '../constants/domain';
import { getUtmFromSearch, getUtmTool } from '../utils/utm';

const VacancyList = ({ vacancySubmit, history }) => {
    const [modal, setModal] = useState(vacancySubmit || null);

    const mutation = useMutation(({ vacancy, domain, values }) => {
        const url = vacancy ? `vacancy/${vacancy}/${domain}` : `domain/${domain}`;

        return axios.post(`/api/public/${url}/applicant`, {
            ...values,
            _utm: getUtmFromSearch(),
            _tool: getUtmTool(),
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': 'ru',
            }
        })
    });
    const { isLoading, error, data } = useQuery("vacancies", () =>
        axios.get(`/api/public/vacancy`, {
            params: {
                filter: {
                    companyCode: COMPANY,
                    domainName: DOMAIN,
                },
                relations: ['cities', 'classifiers', 'domains', 'domains.company', 'domains.vacancyFormPreset']
            }
        }).then((res) => res.data)
    );

    const vacancies = (path(['items'], data) || []);
    const domainForm = path(['items', 0, '_relations', 'domains', 0], data) || {};

    useEffect(() => {
        if (vacancySubmit && !((vacancySubmit === 'submit') || (vacancySubmit === 'submit-success'))) {
            setModal(null);
            history.replace('/');
        }
    }, [vacancySubmit, history]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setModal(`${modal}-success`);
            history.push(`/${modal}-success`);
            mutation.reset();
        }
    }, [mutation.isSuccess, modal, mutation, history]);

    const openModal = modal => {
        history.push(`/${modal}`);
        setModal(modal);
    };
    const closeModal = () => {
        history.push(`/`);
        setModal(null);
    };

    return (
        <div className="vacancy-list">
            <div className="container">
                {/* eslint-disable-next-line */}
                <a name="vacancies" id="vacancies" />
            </div>
            <div className="row">
                <div className="col-1">
                    <div className="content">
                        <div>
                            <h3>Региональный управляющий</h3>
                            <div className="item">
                                <div className="title">ОБЯЗАННОСТИ</div>
                                <ul>
                                    <li>обеспечение эффективной сбытовой деятельности на&nbsp;закрепленных объектах (АЗС, нефтебазы, технические службы)</li>
                                    <li>обеспечение бесперебойной и&nbsp;безопасной технической эксплуатации закрепленных объектов</li>
                                    <li>обеспечение выполнения Политики управления человеческим капиталом ПАО&nbsp;&laquo;ЛУКОЙЛ&raquo; в&nbsp;регионе деятельности</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="item">
                                <div className="title">УСЛОВИЯ</div>
                                <ul>
                                    <li>трудоустройство по ТК РФ</li>
                                    <li>стабильная заработная плата</li>
                                    <li>корпоративное обучение</li>
                                    <li>полный социальный пакет</li>
                                </ul>
                            </div>
                            <div className="item">
                                <div className="title">ТРЕБОВАНИЯ</div>
                                <ul>
                                    <li>образование высшее (техническое, экономическое, юридическое)</li>
                                    <li>стаж работы на&nbsp;руководящих должностях не&nbsp;менее 5&nbsp;лет</li>
                                    <li>знание внутренних процедур и&nbsp;регламентов работы Бизнес-сегмента &laquo;Нефтепродуктообеспечение в&nbsp;РФ&raquo;, знание основных бизнес-процессов Общества НПО (для внутренних кандидатов)</li>
                                    <li>мобильность (готовность переезда в&nbsp;другой регион)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="controls">
                        <button className="btn btn-outline btn-black" onClick={() => openModal('submit')}>ОТКЛИКНУТЬСЯ</button>
                    </div>
                </div>
            </div>
            <Modal isOpen={!!modal} onRequestClose={closeModal} ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Вакансия</h3>
                    <button className="btn-close" onClick={closeModal}>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 8L8 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 8L24 24" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body">
                    { vacancySubmit === 'submit' ? (
                        error || (!isLoading && !vacancies.length) ? (
                            <p style={{ 'textAlign': 'center' }}>
                                {error ? (
                                    'Не удалось загрузить вакансию, попробуйте обновить страницу'
                                ) : (
                                    'Нет доступных вакансий'
                                )}
                            </p>
                        ) : (
                            isLoading ? 'Загрузка вакансии...' : (
                                <Vacancy
                                    mutation={mutation}
                                    vacancy={vacancies[0]}
                                    domain={domainForm}
                                />
                            )
                        )
                    ) : (
                        <h2>ВАША АНКЕТА НАПРАВЛЕНА В ОТДЕЛ ПО РАБОТЕ С ПЕРСОНАЛОМ</h2>
                    )}
                </div>
            </Modal>
        </div>
    );
}

export default VacancyList;
